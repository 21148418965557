import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Shapeable } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { classNames } from '@shapeable/utils';
import { SubTopic } from '@shapeable/gesda-types';
import { ContentTabs, ContentTabSpec, useView } from '@shapeable/ui';
import { AiUpperCaseIconGlyph } from '@shapeable/icons';
import { compact } from 'lodash';
import { sortBy } from 'lodash';
import { SubTopicView } from 'types';
const cls = classNames('sub-topic-tabs-layout');

// -------- Types -------->

// -------- Props -------->

export type SubTopicTabsLayoutProps = Classable & HasChildren & { 
  entity?: SubTopic;
};

export const SubTopicTabsLayoutDefaultProps: Omit<SubTopicTabsLayoutProps, 'entity'> = {
};

// -------- Child Component Props -------->

type ContainerProps = {

};

// -------- Styles -------->


const TabsStyles = breakpoints({
  base: css`
  `,
});

// -------- Components -------->

const My = {
  Tabs: styled(ContentTabs)<ContainerProps>`${TabsStyles}`,

};

export const SubTopicTabsLayout: Shapeable.FC<SubTopicTabsLayoutProps> = (props) => {
  const { className, children, entity } = props;

  const { color, citations = [], feedEntries = [] } = entity;

  const resources = [...citations, ...feedEntries];

  const tabs: ContentTabSpec<SubTopicView>[] = compact([
    {
      id: 'radar-ai',
      icon: <AiUpperCaseIconGlyph />
    },
    {
      id: 'overview',
      label: 'Overview',
    },
    !!resources.length &&
    {
      id: 'resources',
      label: 'Resources',
      count: resources.length,
    },
  ]);

  const { activeView, setActiveView } = useView<SubTopicView>(`topic-${entity.slug}`, 'overview');
  
  return (
    <My.Tabs onTabClick={setActiveView} activeTab={activeView} items={tabs} colors={{ border: color?.value }} />
  )
};

SubTopicTabsLayout.defaultProps = SubTopicTabsLayoutDefaultProps;
SubTopicTabsLayout.cls = cls;