import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Shapeable, Horizon } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { classNames } from '@shapeable/utils';
import { Grid, GridProps, SectionTitle } from '@shapeable/ui';
import { HorizonCard } from './horizon-card';
import { sortBy } from 'lodash';
const cls = classNames('horizon-grid-layout');

// -------- Types -------->

export type HorizonGridLayoutProps = Classable & HasChildren & Omit<GridProps, 'items'> & { 
  items: Horizon[];
  title?: string;
};

export const HorizonGridLayoutDefaultProps: HorizonGridLayoutProps = {
  items: [],
  title: 'Future Horizons:',
  spacing: 3,
  desktopAutoColumns: 3,
  tabletAutoColumns: 3,
  maxColumns: 3,
};

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
  `,
});

const TitleStyles = breakpoints({
  base: css`
  `,
});

const GridStyles = breakpoints({
  base: css`
  `,
});

const CardStyles = breakpoints({
  base: css`
  `,
});


// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
  Title: styled(SectionTitle)`${TitleStyles}`,

  Grid: styled(Grid)`${GridStyles}`,
    Card: styled(HorizonCard)`${CardStyles}`,
};

export const HorizonGridLayout: Shapeable.FC<HorizonGridLayoutProps> = (props) => {
  const { className, items, title, children, ...rest } = props;
  
  return (
    <My.Container className={cls.name(className)}>
      <My.Title>{title}</My.Title>
      {children}
      <My.Grid {...rest} items={sortBy(items, 'type.years').map(horizon => <My.Card entity={horizon} />)} />
    </My.Container>
  )
};

HorizonGridLayout.defaultProps = HorizonGridLayoutDefaultProps;
HorizonGridLayout.cls = cls;