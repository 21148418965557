import * as React from 'react';
import { graphql } from 'gatsby';
import { createGatsbyPageComponent } from '@shapeable/ui';
import { SubTopicExplorerLayout } from '../../components/entities/sub-topic-explorer-layout';
import { transformSubTopic } from '../../utils/transforms';

export default createGatsbyPageComponent('SubTopic', { layout: SubTopicExplorerLayout, transform: transformSubTopic });

export const subTopicQuery = graphql`
  query SubTopicQuery($id: ID!) {
    platform {
      subTopic(id: $id) {
        id slug name path __typename outlineNumber
        intro { text }

        color { id name slug value }
        embeds {
          citations {
            slug url name authors { name } authorShowsEtAl edition publication accessDate startPage volume footnoteNumber year
          }
          imageAssets {
            id
            slug
            image {
              id url url2x width height type 
            }
          }
        }
        openGraph { 
          title description { plain }
          image { url url2x thumbnails { large { url url2x } } }
        }
        banner { 
          id name slug 
          image { 
            id url
          }
        }
        description { text }
        anticipationScores { text }
        anticipationScoresImage {
          id
          image {
            id url url2x width height
          }
        }
        feedEntries {
          __typename _schema { label pluralLabel }
          id name slug url published websiteName summary { text }
          image { url url2x }
          openGraph { image { url url2x thumbnails { card { url url2x } } } }
        }
        horizons {
          __typename id name slug
          intro { text }
          description { text }
          color { __typename id name slug value }
          type { __typename id name slug years title subtitle }
          embeds {
            citations {
              slug url name authors { id name } authorShowsEtAl edition publication accessDate startPage volume footnoteNumber year
            }
          }
        }
        topic { 
          id path name slug __typename
          outlineNumber
          color { id name slug value }
          openGraph { id image { id url url2x } }
          committeeChairs {
            __typename id name slug
            organisation { id name }
            photo { id url url2x }
          }
          committeeMembers {
            __typename id name slug
            organisation { id name }
            photo { id url url2x }
            topicExpertise {
              id name slug
              topic { id name slug path outlineNumber color { value } }
            }
          }

          trend { 
            id name outlineNumber slug __typename
          } 
        }
        connectedTrends {
          id name slug path outlineNumber color { value }
        }
        connectedTopics {
          id name slug path outlineNumber color { value }
        }
        connectedSubTopics {
          id name slug path outlineNumber color { value }
        }
        connectedPages {
          id name typeLabel slug path __typename type { name slug }
          trend { id name slug path outlineNumber color { value } }
          openGraph { image { url url2x } }
        }
      }
    }
  }
`;
