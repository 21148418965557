import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Shapeable } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { classNames } from '@shapeable/utils';
import { SubTopic } from '@shapeable/gesda-types';
import { ContentNode, ImageAsset, MarkdownContent, useLang } from '@shapeable/ui';
const cls = classNames('sub-topic-anticipatory-scores-layout');

// -------- Types -------->

export type SubTopicAnticipatoryScoresLayoutProps = Classable & HasChildren & { 
  entity?: SubTopic;
};

export const SubTopicAnticipatoryScoresLayoutDefaultProps: Omit<SubTopicAnticipatoryScoresLayoutProps, 'entity'> = {
};

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    display: flex;
    gap: ${theme.UNIT(4)};
    flex-direction: column;
  `,
  tablet: css`
    flex-direction: row;
    align-items: flex-start;
    gap: ${theme.UNIT(2)};
  `,
});

const HeadingStyles = breakpoints({
  base: css`
    font-family: ${theme.FONT('serif')};
    font-weight: 400;
    font-size: 1.4em;
    margin-top: 0;
    margin-bottom: 1em;
  `,
});

const BodyStyles = breakpoints({
  base: css`

  `,
  tablet: css`
    width: 50%;
  `,
});

const TextStyles = breakpoints({
  base: css`
    color: ${theme.COLOR('text')};
    font-weight: 300;
    line-height: 1.45em;
  `,
});

const ImageStyles = breakpoints({
  base: css`
    margin: ${theme.UNIT(8)} ${theme.UNIT(2)} ${theme.UNIT(4)};
  `,
  tablet: css`
    margin: ${theme.UNIT(2)};
    width: 50%;
  `
});


// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
    Body: styled.div`${BodyStyles}`,
      Heading: styled.h1`${HeadingStyles}`,
      Content: styled(ContentNode)`${TextStyles}`,
    Image: styled(ImageAsset)`${ImageStyles}`,
};

export const SubTopicAnticipatoryScoresLayout: Shapeable.FC<SubTopicAnticipatoryScoresLayoutProps> = (props) => {
  const { className, children, entity } = props;

  const { name, anticipationScores, anticipationScoresImage } = entity;

  const t = useLang()
  
  return (
    <My.Container className={cls.name(className)}>
      <My.Body>
        <My.Heading>{name} - {t('Anticipation Scores')}</My.Heading>
        <My.Content entity={anticipationScores} />
      </My.Body>

      <My.Image entity={anticipationScoresImage} />
    </My.Container>
  )
};

SubTopicAnticipatoryScoresLayout.defaultProps = SubTopicAnticipatoryScoresLayoutDefaultProps;
SubTopicAnticipatoryScoresLayout.cls = cls;