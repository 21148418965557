import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Shapeable } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { classNames } from '@shapeable/utils';
import { SubTopic } from '@shapeable/gesda-types';
import { ContentEntityIntro, ContentNode, EntityGrid, HorizonIndicators, useView } from '@shapeable/ui';
import { SubTopicView } from 'types';
import { GptLayout } from '@shapeable/gpt';
import { HorizonGridLayout } from './horizon-grid-layout';
import { SubTopicAnticipatoryScoresLayout } from './sub-topic-anticipatory-scores-layout';
const cls = classNames('sub-topic-view-layout');

// -------- Types -------->

// -------- Props -------->

export type SubTopicViewLayoutProps = Classable & HasChildren & { 
  entity?: SubTopic;
};

export const SubTopicViewLayoutDefaultProps: Omit<SubTopicViewLayoutProps, 'entity'> = {
};

// -------- Child Component Props -------->

type ContainerProps = {

};

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    padding-top: ${theme.UNIT(4)};
  `,
});

const SectionStyles = breakpoints({
  base: css`
    display: flex;
    flex-direction: column;
    gap: ${theme.UNIT(2)};
  `,
});

const GptLayoutStyles = breakpoints({
  base: css`
    .shp--gpt-prompt {
      &:first-child {
        border-top: none;
      }
    };
  `,
});

const IntroStyles = breakpoints({
  base: css`
    font-size: 1em;
    font-weight: 300;
  `,
});

const ResourcesStyles = breakpoints({
  base: css`
    
  `,
});

const ContentStyles = breakpoints({
  base: css`
    margin-bottom: ${theme.UNIT(5)};
    font-weight: 300;
  `,
});

const HorizonsStyles = breakpoints({
  base: css`
    margin-top: ${theme.UNIT(8)};
    margin-bottom: ${theme.UNIT(8)};
  `,
});

const HorizonIndicatorsStyles = breakpoints({
  base: css`
    margin-bottom: ${theme.UNIT(4)};
  `,
});

const AnticipationScoresLayoutStyles = breakpoints({
  base: css`
    
  `,
});

// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,

  Section: styled.section`${SectionStyles}`,
      GptLayout: styled(GptLayout)`${GptLayoutStyles}`,

      Intro: styled(ContentEntityIntro)`${IntroStyles}`,
      Content: styled(ContentNode)`${ContentStyles}`,
      Horizons: styled(HorizonGridLayout)`${HorizonsStyles}`,
        HorizonIndicators: styled(HorizonIndicators)`${HorizonIndicatorsStyles}`,
      AnticipationScoresLayout: styled(SubTopicAnticipatoryScoresLayout)`${AnticipationScoresLayoutStyles}`,

      Resources: styled(EntityGrid)`${ResourcesStyles}`,
};

export const SubTopicViewLayout: Shapeable.FC<SubTopicViewLayoutProps> = (props) => {
  const { className, children, entity } = props;

  const { description, citations = [], feedEntries = [], horizons = [], anticipationScores, anticipationScoresImage } = entity;

  const resources = [...citations, ...feedEntries];

  const hasHorizons = !!horizons.length;
  const hasAnticipationScores = !!anticipationScores && !!anticipationScoresImage;

  const { showView } = useView<SubTopicView>(`sub-topic-${entity.slug}`, 'overview');
  
  return (
    <My.Container className={cls.name(className)}>
    {
          showView('radar-ai') && 
          <My.Section>
            <My.GptLayout entity={entity} />
          </My.Section>
        }
        {
          showView('overview') && 
          <My.Section>
            <My.Intro entity={entity} />
            {
              hasHorizons && 
                <My.Horizons 
                  items={horizons}>
                  <My.HorizonIndicators />
                </My.Horizons>
            }
            <My.Content entity={description} />
            {
              hasAnticipationScores && 
              <My.AnticipationScoresLayout entity={entity} />
            }
          </My.Section>
        }
        {
          showView('resources') && 
          <My.Section>
            <My.Resources items={resources} spacing={4} />
          </My.Section>
        }
    </My.Container>
  )
};

SubTopicViewLayout.defaultProps = SubTopicViewLayoutDefaultProps;
SubTopicViewLayout.cls = cls;